

function Projects() {
    return (
        <>
            <div className="textDiv">
                <p>Below you can find a few projects I have made for my portfolio. These will grow and change over the next
                    few
                    months. These projects are currently hosted on Heroku "Eco" servers, and will take 10-15 seconds to start initially - your patience is appreciated.</p>
            </div>
            <div className="navCardContainer">
                <div className="navCard" id="first"><a className="navA" href="https://sheltered-temple-64414.herokuapp.com/">STOCKWATCH<br /><br /><span className="linkText">A personal trade compliance tool, utilizing Plaid's API to connect to user's bank accounts. Python/Django back-end, React/Typescript front-end. Postgres DB.</span></a></div>
                <div className="navCard" id="second"><a className="navA" href="https://pacific-gorge-68572.herokuapp.com/">Flim-Flam (microblog)<br /><br /><span className="linkText">A microblogging site built using MongoDB, Express, Node.js. It utilizes Microsoft Azure Content Moderator. Features jQuery infinite scroll. Pages built using EJS.</span></a></div>
                <div className="navCard" id="third"><a className="navA" href="https://connect4unit4.herokuapp.com/">Connect 4<br /><br /><span className="linkText">A game of Connect 4, utilizing websockets for real-time updates. Python/Django back-end, React front-end, Postgres DB. Group Project.</span></a></div>
                <div className="navCard" id="fourth"><a className="navA" href="https://unit-3-frontend.herokuapp.com/">Onparo<br /><br /><span className="linkText">A website similar to Reddit. This was a group project utilizing Node/Express back-end, React front-end, and MongoDB's Atlas DB.</span></a></div>
            </div>
        </>
    )
}

export default Projects