function Footer() {
    return (
        <footer id="stickyFooter">
            <a href="https://github.com/g-star423">
                <div className="footerCard" id="git">
                    GitHub
                </div>
            </a>
            <a href="https://www.linkedin.com/in/gregshoup/">
                <div className="footerCard" id="linkedIn">
                    LinkedIn
                </div>
            </a>
        </footer>
    )
}

export default Footer