import React, { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    function handleMenu(event) {
        if (mobileMenuOpen) {
            setMobileMenuOpen(false)
        } else {
            setMobileMenuOpen(true)
        }
    }
    const linkUl = (
        <ul>
            <li><Link to="/homepage">Homepage</Link></li>
            <li><Link to='/bio' >Bio</Link></li>
            <li><Link to='/resume'>Resumé</Link></li>
            <li><Link to='/projects'>Projects</Link></li>
            <li><Link to='/art'>Art</Link></li>
        </ul>
    )
    const mobileLinks = (
        <div id="mobileNavLinks">
            {linkUl}
        </div>
    )

    return (
        <header>
            <Link to="/homepage"><h1 id="myName" >Greg Shoup</h1></Link>
            <nav>
                {linkUl}
            </nav>
            <div id="mobileNav" onClick={handleMenu} ><span className="material-symbols-outlined">
                menu
            </span>
            </div>
            {mobileMenuOpen ? mobileLinks : null}

        </header>
    )
}

export default Header