
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
function Art() {
    return (
        <>
            <div className="textDiv">
                <h4 id="artistStatement">In my spare time, I enjoy painting and visiting art exhibitions. I am inspired by color, and the vibrancy of mono-pigment paints. Digital representations of my work are only approximations, and all my paintings intentionally use colors outside the RGB gamut. Below are some of my selected works.</h4>
            </div>
            <div className="carousel-div">
                <Carousel showArrows={true}>
                    <div>
                        <img src="https://i.imgur.com/Aw8Oh1i.jpg" alt="abstract color field painting" />
                        <p className="legend"><em>Moneyshot</em>, 2020<br />Acrylic on Canvas, 3ft. x 3ft.</p>
                    </div>
                    <div>
                        <img src="https://i.imgur.com/abw5kJU.jpg" alt="abstract color field painting" />
                        <p className="legend"><em>On The Move</em>, 2018<br />Acrylic on Canvas, 3ft. x 3ft.</p>
                    </div>
                    <div>
                        <img src="https://i.imgur.com/ZR3gN3P.jpg" alt="abstract color field painting" />
                        <p className="legend"><em>Self-portrait</em>, 2018<br />Acrylic on Canvas, 4ft. x 3ft.</p>
                    </div>
                </Carousel>
            </div>
        </>
    )
}

export default Art