
function Bio() {
    return (
        <div>
            <img src="https://i.imgur.com/mQsU2YD.jpg" alt="picture of Greg Shoup" id="bioPic" />
            <div id="bioDiv">
                <p>My name is Greg Shoup, and I’m from Chattanooga, TN. <br />I have recently made a transition to software engineering by completing General
                    Assembly's Software Engineering Immersive program. I am also an experienced professional in the
                    Governance, Risk, and Compliance (GRC) space who specializes in audit, process improvement, and project
                    management. </p>
                <p>I am currently seeking a role that will combine my software development with my previous financial compliance experience.</p>
                <p>In my spare time, I love biking, hiking, stand-up paddleboarding, and traveling.</p>
            </div>
        </div>
    )
}

export default Bio
