import './index.css';
import Header from './components/HeaderNavbar';
import Footer from './components/Footer';
import Resume from './components/Resume';
import Homepage from './components/Homepage';
import Art from './components/Art';
import Bio from './components/Bio';
import Projects from './components/Projects';
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";

function App() {

  return (
    <main>
      <Router>
        <Header />
        <Routes>
          <Route path='/homepage' element={<Homepage />} />
          <Route path='/' element={<Homepage />} />
          <Route path='/bio' element={<Bio />} />
          <Route path='/resume' element={<Resume />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/art' element={<Art />} />
        </Routes>
        <Footer />
      </Router>
    </main>
  );
}

export default App;
