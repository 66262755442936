
//should make this page links to my PDFS if on mobile, and not have the text. Otherwise show full resumes and links to PDFs.
function Resume() {
    // const [finJargon, setFinJargon] = useState(false)
    return (
        <>
            <div className="textDiv">
                <p>Please click the below link to view the latest version of my resume.</p>
            </div>
            <div id="resumeDiv">
                <a href="./RedactedGregoryShoupResume2023.pdf"><button className="bauhaus-button">View Resume</button></a>
            </div>
        </>
    )
}

export default Resume