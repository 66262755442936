import { Link } from "react-router-dom";

function Homepage() {

    return (
        <>
            <div className="textDiv">
                <p> Welcome to my personal website! Using the links above or below, please navigate to the information you
                    need.
                    There
                    are several projects available to try on the projects page. For contact, please use the LinkedIn link to
                    message me.</p>
            </div>
            <div className="navCardContainer">
                <div className="navCard" id="first" ><Link to="/bio">Bio<br /><br /><span
                    className="linkText">Biographical
                    Information</span></Link>
                </div>
                <div className="navCard" id="second" ><Link to="/resume">Resume<br /><br /><span className="linkText">
                </span></Link></div>
                <div className="navCard" id="third" ><Link to="/projects">Projects<br /><br /><span
                    className="linkText">A few projects I've created or worked on.</span></Link></div>
                <div className="navCard" id="fourth" ><Link to="/art">Art<br /><br /><span className="linkText">Some
                    paintings I've done in my spare time.</span></Link></div>
            </div>
        </>
    )
}

export default Homepage